@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* You can add global styles to this file, and also import other style files */

body {
    overflow-x: hidden;
}

@font-face {
    font-family: "Ethnocentric";
    src: url("./assets/fonts/ethnocentric rg it.otf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

.main::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .main::-webkit-scrollbar-track {
    margin-top: 100px;
    margin-bottom: 20px;
    //background: #303233; 
    background: transparent;
  }